export const data = [
  {
    title: "Grad Beograd",
    city: "Beograd",
    id: "RS-00",
    isRed: ["jabuka", "kruška", "breskva", "kajsija"],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "šljiva",
      "višnja",
      "trešnja",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Grad Beograd ima status posebne teritorijalne jedinice u Srbiji sa svojom lokalnom samoupravom. Njegova teritorija podeljena je na 17 gradskih opština: Čukarica, Novi Beograd, Palilula, Rakovica, Savski venac, Stari grad, Voždovac, Vračar, Zemun, Zvezdara, Barajevo, Grocka, Lazarevac, Mladenovac, Obrenovac, Sopot i Surčin. ",
  },
  {
    title: "Severnobački okrug",
    city: "Subotica",
    id: "RS-01",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Subotica je najseverniji grad u Republici Srbiji, drugi po broju stanovnika u Autonomnoj Pokrajini Vojvodini. Nalazi se na 10 km udaljenosti od granice Srbije sa Mađarskom.",
  },
  {
    title: "Srednjebanatski okrug",
    city: "Zrenjanin",
    id: "RS-02",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Zrenjanin je najveći grad u Banatu i treći najveći grad Vojvodine (iza Novog Sada i Subotice). Administrativni je centar Srednjebanatskog okruga, kao i istoimene opštine. ",
  },
  {
    title: "Severnobanatski okrug",
    city: "Kikinda",
    id: "RS-03",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Kikinda predstavlja administrativni centar Severnobanatskog okruga Srbije. U severnom delu Banata, u neposrednoj blizini srpsko-rumunske granice, prostire se velika opština Kikinda, središte ekonomskog i društvenog života ovog dela Vojvodine.",
  },
  {
    title: "Južnobanatski okrug",
    city: "Pančevo",
    id: "RS-04",
    isRed: ["jabuka", "kruška", "breskva", "kajsija"],
    isOrange: [
      "šljiva",
      "višnja",
      "trešnja",
      "orah",
      "lešnik",
      "badem",
      "jagoda",
      "dunja",
    ],
    isYellow: ["borovnica", "malina", "kupina", "ogrozd", "ribizla"],
    p1:
      "Pančevo spada u Juznobanatski okrug, sastoji se od 10 naselja: 2 gradska i 8 seoskih naselja.",
  },
  {
    title: "Zapadnobački okrug",
    city: "Sombor",
    id: "RS-05",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Sombor predstavlja administrativni centar Zapadnobačkog okruga, grad i njegov atar, su na dnu basena nekadašnjeg Panonskog mora. Sombor je lako uočljiv jer je prosečna visina njegovog atara samo 90m.",
  },
  {
    title: "Južnobački okrug",
    city: "Novi Sad",
    id: "RS-06",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Novi Sad je najveći grad Autonomne Pokrajine Vojvodine, pokrajine na severu Republike Srbije, kao i sedište pokrajinskih organa vlasti i administrativni centar Južnobačkog okruga. Grad se nalazi na granici Bačke i Srema, na obalama Dunava i Malog bačkog kanala, u Panonskoj ravnici i na severnim obroncima Fruške gore.",
  },
  {
    title: "Sremski okrug",
    city: "Sremska mitrovica",
    id: "RS-07",
    isRed: [],
    isOrange: [
      "jabuka",
      "kruška",
      "dunja",
      "orah",
      "lešnik",
      "badem",
      "jagoda",
    ],
    isYellow: [
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Sremska Mitrovica predstavlja administrativno središte Sremskog okruga. Grad se nalazi u severozapadnom delu Srbije i jugozapadnom delu Vojvodine, na kontaktu triju različitih morfoloških celina: sremske ravnice, mačvanske ravnice i fruškogorskog pobrđa.",
  },
  {
    title: "Mačvanski okrug",
    city: "Šabac",
    id: "RS-08",
    isRed: [],
    isOrange: [
      "jabuka",
      "kruška",
      "dunja",
      "šljiva",
      "višnja",
      "trešnja",
      "orah",
      "lešnik",
      "badem",
      "jagoda",
    ],
    isYellow: [
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Šabac je grad u Mačvanskom okrugu, nalazi se u zapadnoj Srbiji na obali reke Save. Privredno je, kulturno i administrativno središte Mačvanskog okruga.  ",
  },
  {
    title: "Kolubarski okrug",
    city: "Valjevo",
    id: "RS-09",
    isRed: ["malina", "kupina", "ogrozd", "ribizla"],
    isOrange: [
      "jabuka",
      "kruška",
      "dunja",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "orah",
      "lešnik",
      "badem",
      "borovnica",
      "jagoda",
    ],
    isYellow: [],
    p1:
      "Valjevo je grad u Srbiji, sedište opštine Valjevo i Kolubarskog upravnog okruga. Gradsko jezgro smešteno je u kotlini kroz koju protiče reka Kolubara. Valjevo spada među veća i razvijenija naselja u Srbiji. ",
  },
  {
    title: "Podunavski okrug",
    city: "Smederevo",
    id: "RS-10",
    isRed: ["jabuka", "kruška", "breskva", "kajsija"],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "šljiva",
      "višnja",
      "trešnja",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Nalazi se na obalama Dunava u severoistočnom delu Srbije. Smederevo je sa izgradnjom Smederevske tvrđave 1430. postala prestonica Srpske despotovine pošto je Beograd, dotadašnja prestonica, vraćen Ugarskoj 1427. godine. Smederevsku tvrđavu je osnovao tadašnji srpski despot Đurađ Branković. Smederevska tvrđava je tada predstavljala najveću ravničarsku tvrđavu u Evropi. Nalazi se u severoistočnom delu Republike Srbije, na drugoj po veličini evropskoj reci Dunavu.  ",
  },
  {
    title: "Braničevski okrug",
    city: "Požarevac",
    id: "RS-11",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Opština Požarevac je bila jedna od opština u Republici Srbiji, koja je postojala do 27. decembra 2007. godine, nakon čega je dobila status grada. Nalazila se u Centralnoj Srbiji i spadala je u Braničevski okrug.  Centar opštine je bio Požarevac. Opština Požarevac se sastojala od 27 naselja. ",
  },
  {
    title: "Šumadijski okrug",
    city: "Kragujevac",
    id: "RS-12",
    isRed: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
    ],
    isOrange: [
      "orah",
      "lešnik",
      "badem",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
      "jagoda",
      "dunja",
    ],
    isYellow: [],
    p1:
      "Kragujevac je četvrti grad po veličini u Srbiji (ne računajući Kosovo i Metohiju), i sedište Šumadijskog okruga. Kragujevac je značajan privredni, kulturni, obrazovni i zdravstveni centar Šumadije, Pomoravlja i susednih regiona. Makroregionalni je centar za regione: Čačka, Kraljeva, Užica, Jagodine, Kruševca, Smedereva, Požarevca i severnog Kosova.  ",
  },
  {
    title: "Pomoravski okrug",
    city: "Jagodina",
    id: "RS-13",
    isRed: [],
    isOrange: [
      "orah",
      "lešnik",
      "badem",
      "jagoda",
      "dunja",
      "breskva",
      "kajsija",
    ],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Jagodina (prethodno Jagodna i Svetozarevo), gradsko naselje i sedište grada Jagodine u Pomoravskom okrugu.",
  },
  {
    title: "Borski okrug",
    city: "Bor",
    id: "RS-14",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Bor je grad u Srbiji, poznat po rudnicima i topionici bakra. Predstavlja administrativno srediste Borskog okruga.",
  },
  {
    title: "Zaječarski okrug",
    city: "Zaječar",
    id: "RS-15",
    isRed: [],
    isOrange: [
      "jabuka",
      "kruška",
      "dunja",
      "orah",
      "lešnik",
      "badem",
      "jagoda",
    ],
    isYellow: [
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Opština Zaječar je opština u Srbiji. Nalazi u centralnom delu u Timočke Krajine, u Zaječarskom okrugu. Središte opštine je grad Zaječar.",
  },
  {
    title: "Zlatiborski okrug",
    city: "Užice",
    id: "RS-16",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Grad Užice je jedinica lokalne samouprave u Zlatiborskom okrugu na zapadu Srbije. U administrativnom smislu, podeljen je na dve gradske opštine: Sevojno i Užice.",
  },
  {
    title: "Moravički okrug",
    city: "Čačak",
    id: "RS-17",
    isRed: [
      "šljiva",
      "višnja",
      "trešnja",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    isOrange: [
      "jabuka",
      "kruška",
      "dunja",
      "breskva",
      "kajsija",
      "orah",
      "lešnik",
      "badem",
      "borovnica",
      "jagoda",
    ],
    isYellow: [],
    p1:
      "Čačak je grad u Srbiji koji se nalazi 140 km južno od Beograda. Predstavlja upravno sjedište Moravičkog okruga.",
  },
  {
    title: "Raški okrug",
    city: "Kraljevo",
    id: "RS-18",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Kraljevo je grad u Srbiji u Raškom okrugu.Kraljevo se nalazi na trima rekama: Ibar, Zapadna Morava i Ribnica. Gradski grb se sastoji od sedam kruna, što simbolizuje krunisanje sedam kraljeva u Manastiru Žiča, koji se nalazi na 5 km od centra grada. Kraljevo je administrativni, privredni, sportski i kulturni centar uže Srbije. Takođe i raskrsnica važnih magistralnih pravaca.",
  },
  {
    title: "Rasinski okrug",
    city: "Kruševac",
    id: "RS-19",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Kruševac je grad koji se nalazi u središnjem delu Srbije, u dolini Zapadnog Pomoravlja, na reci Rasini. Opština Kruševac obuhvata 101 naselje. Kruševac je centar Rasinskog okruga. ",
  },
  {
    title: "Nišavski okrug",
    city: "Niš",
    id: "RS-20",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Niš je najveći grad u jugoistočnoj Srbiji i sedište Nišavskog upravnog okruga, po broju stanovnika Niš treći grad po veličini u Srbiji (posle Beograda i Novog Sada). Niš je bio administrativni, vojni i trgovinski centar različitih država i carstava kojima je, tokom svoje duge istorije, pripadao. ",
  },
  {
    title: "Toplički okrug",
    city: "Prokuplje",
    id: "RS-21",
    isRed: ["šljiva", "višnja", "trešnja"],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Prokuplje predstavlja središte istoimene opštine kao i Topličkog okruga.",
  },
  {
    title: "Pirotski okrug",
    city: "Pirot",
    id: "RS-22",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Pirot je gradsko naselje u opštini Pirot u Pirotskom okrugu. Pirot se graniči sa četiri srpske opštine Dimitrovgrad, Knjaževac, Bela Palanka i Babušnica, kao i sa Bugarskom na dužini od 65 kilometara.",
  },
  {
    title: "Jablanički okrug",
    city: "Leskovac",
    id: "RS-23",
    isRed: ["šljiva", "višnja", "trešnja"],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Leskovac je grad u Jablaničkom okrugu. Poznat je po petorečju, pojavi retkoj u svetu, koja se nalazi u Leskovačkoj kotlini. Kao administrativni centar Jablaničkog okruga grad Leskovac ima više institucija u regionu. ",
  },
  {
    title: "Pčinjski okrug",
    city: "Vranje",
    id: "RS-24",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Vranje je grad na jugu Srbije, sedište Pčinjskog okruga. Vranje se nalazi u Vranjskoj kotlini, na Vranjskoj reci, nedaleko od njenog ušća u Južnu Moravu.",
  },
  {
    title: "Kosovski okrug",
    city: "Priština",
    id: "RS-25",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Priština predstavlja jedan od najvećih gradova na Kosovu i Metohiji Republika Srbija.",
  },
  {
    title: "Pećki okrug",
    city: "Peć",
    id: "RS-26",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Peć je grad koji se nalazi u zapadnom dijelu Kosova (oblast Metohija).",
  },
  {
    title: "Prizrenski okrug",
    city: "Prizren",
    id: "RS-27",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Prizren grad je koji se nalazi na jugu Kosova. Sedište je istoimene opštine i Prizrenskog okruga.",
  },
  {
    title: "Kosovsko-Mitrovački okrug",
    city: "Kosovska mitrovica",
    id: "RS-28",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1:
      "Kosovska Mitrovica grad na severu Kosova i Metohije, u Republici Srbiji.  U Kosovskoj Mitrovici je sedište Kosovskomitrovičkog upravnog okruga, koji osim mitrovičke, zahvata još pet opština: Vučitrn, Srbicu, Zvečan, Zubin Potok i Leposavić.",
  },
  {
    title: "Kosovsko-Pomoravski okrug",
    city: "Gnjilane",
    id: "RS-29",
    isRed: [],
    isOrange: ["orah", "lešnik", "badem", "jagoda", "dunja"],
    isYellow: [
      "jabuka",
      "kruška",
      "šljiva",
      "višnja",
      "trešnja",
      "breskva",
      "kajsija",
      "borovnica",
      "malina",
      "kupina",
      "ogrozd",
      "ribizla",
    ],
    p1: " ",
  },
];
